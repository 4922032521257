import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {Link} from 'gatsby';

export const DisplayFormikState = props =>
    <div style={{margin: '1rem 0'}}>
        <pre
            style={{
                background: '#f6f8fa',
                fontSize: '.65rem',
                padding: '.5rem',
                color: 'red'
            }}
        >
      <strong>props</strong> ={' '}
            {JSON.stringify(props, null, 2)}
    </pre>
    </div>;


const ApplyPages = () => {
    return (
        <Layout darkMode={true}>
            <SEO title="Thank You"/>
            <div className="container">

                <div className="opening">
                    <h1>Thank You</h1>
                </div>
            </div>
            <div className="container">
                <p className="more">
                    <Link to="/apply" className="link">Back</Link>
                </p>
            </div>

        </Layout>
    )
};

export default ApplyPages
